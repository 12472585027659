import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Stock, StocksQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const GET_TRANSACTIONS_URL = `${API_URL}/getallstocks`
const ADD_TRANSACTION_URL = `${API_URL}/addstock`
const EDIT_TRANSACTION_URL = `${API_URL}/editstock`
const DELETE_TRANSACTION_URL = `${API_URL}/deletestock`

const getStocks = (): Promise<StocksQueryResponse> => {
  return axios.get(`${GET_TRANSACTIONS_URL}`).then((response: AxiosResponse) => {
    const responseData = response.data
    if (responseData.success) {
      return responseData.data
    } else {
      throw new Error(responseData.message)
    }
  })
}

const addStock = (client: Stock): Promise<Stock | undefined> => {
  return axios
    .post(ADD_TRANSACTION_URL, client)
    .then((response: AxiosResponse<Response<Stock>>) => response.data)
    .then((response: Response<Stock>) => response.data)
}

const editStock = (client: Stock): Promise<Stock | undefined> => {
  return axios
    .post(EDIT_TRANSACTION_URL, client)
    .then((response: AxiosResponse<Response<Stock>>) => response.data)
    .then((response: Response<Stock>) => response.data)
}

const deleteStock = (id: ID): Promise<void> => {
  const requestData = {
    id: id,
  }

  return axios
    .post(DELETE_TRANSACTION_URL, requestData)
    .then((response: AxiosResponse<void>) => response.data)
}

export {addStock, deleteStock, editStock, getStocks}
