import {useListView} from '../../core/ListViewProvider'
import ListToolbar from './ListToolbar'
import ListGrouping from './ListGrouping'
import ListSearchComponent from './ListSearchComponent'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useAuth} from '../../../../../../modules/auth'

const ListHeader = ({onSearch}: {onSearch: (searchTerm: string) => void}) => {
  const {selected} = useListView()
  const {isAdminRole} = useAuth()

  return (
    <div className='card-header border-0 pt-6'>
      {/* <ListSearchComponent onSearch={onSearch} /> */}
      {isAdminRole && (
        <h3 className='card-title align-items-start flex-column'>
          <Link
            to='/client-management/clients'
            className='btn btn-sm btn-bg-light btn-active-color-primary d-flex align-items-center h-max-content'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-3' />
            Back
          </Link>
        </h3>
      )}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <ListGrouping /> : <ListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default ListHeader
