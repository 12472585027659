import {useEffect, useState} from 'react'
import {KTCard, Response} from '../../../../../_metronic/helpers'
import {ClientEditModal} from './client-edit-modal/ClientEditModal'
import ListHeader from './components/header/ListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
} from './core/QueryResponseProvider'
import {UpcomingPayments} from './core/_models'
import UnpaidTransactionTable from './table/UpcomingPaymentsTable'

import {useUserRoleNavigation} from '../../../../hooks/userRoleBasedNavigation'
import {AppTable} from '../../../../components/table/CustomTable'
import upcomingPaymentsColumns from './table/columns/_columns'
import CustomLoader from '../../../../components/CustomLoader'

const UpcomingPaymentList: React.FC = () => {
  const {isAdminRole} = useUserRoleNavigation()
  const isLoading = useQueryResponseLoading()
  const {itemIdForUpdate} = useListView()
  const initialPayments: Response<UpcomingPayments[]> | never[] = useQueryResponseData()
  const [payments, setPayments] = useState<UpcomingPayments[]>([])
  const [filteredPayments, setFilteredPayments] = useState<UpcomingPayments[]>([])

  useEffect(() => {
    if (Array.isArray(initialPayments)) {
      setPayments(initialPayments)
      setFilteredPayments(initialPayments)
    }
  }, [initialPayments])
  //TODO: upcoming payments - need to change the handle search function
  const handleSearch = (searchTerm: string): void => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    const filtered = payments.filter((payment: UpcomingPayments) => {
      return `${payment?.first_name} ${payment?.last_name}`
        .toLowerCase()
        .includes(lowerCaseSearchTerm)
    })
    setFilteredPayments(filtered)
  }

  return (
    <>
      <KTCard>
        {isAdminRole && <ListHeader onSearch={handleSearch} />}
        <AppTable columns={upcomingPaymentsColumns} tableData={filteredPayments}>
          {isLoading && <CustomLoader />}
        </AppTable>
      </KTCard>
      {itemIdForUpdate !== undefined && <ClientEditModal />}
    </>
  )
}

const UpcomingPaymentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UpcomingPaymentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default UpcomingPaymentListWrapper
