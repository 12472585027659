import {FC} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface AppListToolbarProps {
  onClick: () => void
  title: string
}

const AppListToolbar: FC<AppListToolbarProps> = ({onClick, title}) => {
  return (
    <div className='d-flex justify-content-end' data-kt-client-table-toolbar='base'>
      <button type='button' className='btn btn-primary' onClick={onClick}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {title}
      </button>
    </div>
  )
}
export default AppListToolbar
