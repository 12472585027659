import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import { AuthModel, UserModel } from './_models'
import { getUserByToken } from './_requests'
import { getJwtDataFromToken } from '../../../Utils/Utils'

type UserRoleType = 'admin' | 'client' | 'sub-admin'

const userRoleObj: Record<string, UserRoleType> = {
  '1': 'admin',
  '2': 'sub-admin',
  '3': 'client',
}

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  roleId: string
  changeRoleId: (roleId: string) => void
  userRole: UserRoleType
  isAdminRole: boolean
  clientId: string
  changeClientId: (id: string) => void
}

const initAuthContextPropsState: AuthContextProps = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
  roleId: '1',
  changeRoleId: () => { },
  userRole: userRoleObj['1'],
  isAdminRole: true,
  clientId: '',
  changeClientId: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [roleId, setRoleId] = useState('1')
  const [clientId, setClientId] = useState('')

  const changeRoleId = (roleId: string) => setRoleId(roleId)
  const changeClientId = (id: string) => setClientId(id)

  const userRole = userRoleObj[roleId]
  const isAdminRole = userRole === 'admin'

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    auth ? authHelper.setAuth(auth) : authHelper.removeAuth()
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        roleId,
        changeRoleId,
        userRole,
        isAdminRole,
        clientId,
        changeClientId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, changeRoleId, changeClientId } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
            const decodedUserOutput: any = getJwtDataFromToken(apiToken)
            if (decodedUserOutput?.user_type) {
              if (decodedUserOutput.user_type === 'admin') {
                changeRoleId('1')
              } else if (decodedUserOutput.user_type === 'sub-admin') {
                changeRoleId('2')
              } else if (decodedUserOutput.user_type === 'client') {
                changeRoleId('3')
              }
              changeClientId(decodedUserOutput?.user_id || '')
            }
            console.log('userRole', decodedUserOutput?.user_type)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }
      didRequest.current = true
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth, logout, setCurrentUser, changeRoleId, changeClientId])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthInit, AuthProvider, useAuth }
