import {useEffect, useState} from 'react'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'

interface AppSearchComponentProps {
  onSearch: (searchTerm: string) => void
  searchPlaceholder?: string
}

const AppSearchComponent: React.FC<AppSearchComponentProps> = ({
  onSearch,
  searchPlaceholder = 'Search Client',
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
      onSearch(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm])

  return (
    <div className='card-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-client-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>
  )
}

export default AppSearchComponent
