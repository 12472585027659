import {useNavigate, useParams} from 'react-router-dom'
import {useClientIdContext} from '../pages/reports/UnPaidTransactions.tsx/transaction-list/core/ClientIdContext'
import {useEffect} from 'react'
import {useAuth} from '../modules/auth'
import {routeMap} from '../routing/routes'

export const useUserRoleNavigation = () => {
  const navigate = useNavigate()
  const params = useParams()
  const id = params?.id

  const {changeId} = useClientIdContext()
  const {isAdminRole, clientId} = useAuth()

  useEffect(() => {
    if (!id) return

    if (id && !isAdminRole && id !== clientId) {
      navigate(routeMap.error)
      changeId('')
      return
    }

    if (id) changeId(id as string)
    return () => changeId('')
  }, [])
  return {isAdminRole, clientId}
}
