import {Column} from 'react-table'
import ActionCell from './ActionsCell'
import CustomHeader from './CustomHeader'
import InfoCell from './InfoCell'
import SelectionCell from './SelectionCell'
import SelectionHeader from './SelectionHeader'
import WalletCell from './WalletCell'
import {UpcomingPayments} from '../../core/_models'

const upcomingPaymentsColumns: ReadonlyArray<Column<UpcomingPayments>> = [
  //TODO: change the column names and fields here
  {
    Header: (props) => <SelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <SelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Client' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <InfoCell UpcomingPayments={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Phone' className='min-w-125px' />,
    accessor: 'phone',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Wallet Balance' className='min-w-125px' />
    ),
    id: 'last_login',
    Cell: ({...props}) => (
      <WalletCell wallet_balance={`${props.data[props.row.index]?.wallet_balance || 0}`} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
    ),
    accessor: 'date_of_joining',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionCell id={props.data[props.row.index].id} />,
  },
]

export default upcomingPaymentsColumns
