import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {routeMap} from './routes'
import UnPaidTransactions from '../pages/reports/UnPaidTransactions.tsx'
import UpcomingPayments from '../pages/reports/UpcomingPayments'
import {useAuth} from '../modules/auth'
import { electron } from 'webpack'
import StocksPage from '../pages/stocks/StocksPage'

const PrivateRoutes = () => {
  const ClientsPage = lazy(() => import('../pages/client-management/ClientsPage'))
  const PaymentsPage = lazy(() => import('../pages/payments/PaymentsPage'))
  const {isAdminRole, clientId} = useAuth()
  const initialRouteName = isAdminRole ? routeMap.dashboard : `${routeMap.clients}/${clientId}`

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={initialRouteName} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='clients/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='payments'
          element={
            <SuspensedView>
              <PaymentsPage />
            </SuspensedView>
          }
        />

        <Route path={`${routeMap.unPaidTransaction}/:id`} element={<UnPaidTransactions />} />
        <Route path={`${routeMap.upcomingPayments}/:id`} element={<UpcomingPayments />} />

        <Route path={`${routeMap.stocks}`} element={<StocksPage />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
