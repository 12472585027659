import { useQuery } from 'react-query'
import { isNotEmpty, QUERIES } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { StockEditModalForm } from './StockEditModalForm'

const StockEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const stock = {
    id: itemIdForUpdate
  }
  const {
    isLoading,
    data,
    error,
  } = useQuery(
    `${QUERIES.CLIENTS_LIST}-stock-${itemIdForUpdate}`,
    () => {
      // return getStockById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: false,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <StockEditModalForm isStockLoading={isLoading} stock={{ id: undefined }} />
  }

  if (!isLoading && !error && stock) {
    return <StockEditModalForm isStockLoading={isLoading} stock={stock} />
  }

  return null
}

export { StockEditModalFormWrapper }
