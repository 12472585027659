import {ReactNode, FC} from 'react'

interface AppListHeaderProps {
  children: ReactNode
}

const AppListHeader: FC<AppListHeaderProps> = ({children}) => {
  return <div className='card-header border-0 pt-6'>{children}</div>
}

export default AppListHeader
