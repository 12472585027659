import { useEffect } from 'react'
import { StockEditModalFormWrapper } from './StockEditModalFormWrapper'
import EditModalHeader from '../../../../components/table/EditModalHeader'
import { useListView } from '../core/ListViewProvider'

const StockEditModal = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const onDismiss = () => {
    setItemIdForUpdate(undefined)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <EditModalHeader onDismiss={onDismiss}>
              {
                <>
                  {itemIdForUpdate ? (
                    <h2 className='fw-bolder'>Edit Stock</h2>
                  ) : (
                      <h2 className='fw-bolder'>Add Stock</h2>
                  )}
                </>
              }
            </EditModalHeader>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <StockEditModalFormWrapper />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { StockEditModal }
