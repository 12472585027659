/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import {
  PaginationState,
  QUERIES,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers'
import { useQueryRequest } from './QueryRequestProvider'
import { Stock } from './_models'
import { getStocks } from './_requests'
import { checkQuery } from '../../../../Utils/checkQueryChange'
import { toast } from 'react-toastify'

interface StockQueryResponseProviderProps extends WithChildren {
  // id: string
}

const QueryResponseContext = createResponseContext<Stock>(initialQueryResponse)
const StockQueryResponseProvider: FC<StockQueryResponseProviderProps> = ({
  children,
}) => {
  const { state } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    const isQueryUpdated = checkQuery(query, updatedQuery)
    if (!isQueryUpdated) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.CLIENTS_LIST}-${query}`,
    () => {
      return getStocks()
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(`There was an error loading stocks`)
      },
      onSuccess: (data) => {
        if (Array.isArray(data)) {
          if (data.length === 0) {
            toast.info('No Stock found')
          }
        } else {
          toast.error('Failed to load stocks')
        }
      }
    }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  StockQueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
}
