import { KTSVG } from "../../../../_metronic/helpers"

const InvestedAmountCard = ({ investedAmount, profitLossPercentage }: any) => {
  return (
    <div className={`card card-flush h-md-50 mb-5 mb-xl-10`} >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column w-100'>
          <div className='d-flex align-items-center flex-wrap gap-6 w-100'>
            <div className='d-flex align-items-center w-100'>
              <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>₹</span>

              <span className='fs-3hx fw-bold text-dark me-8 lh-1 ls-n2 w-100'>{investedAmount.toLocaleString() || 0}</span>
            </div>
            <span className={`badge fs-2 ${profitLossPercentage > 0 ? 'badge-light-success' : 'badge-light-danger'}`}>
              {profitLossPercentage > 0 ? (
                <KTSVG
                  path='/media/icons/duotune/arrows/arr066.svg'
                  className='svg-icon-5 ms-n1 svg-icon-success'
                />
              ) : (
                <KTSVG
                  path='/media/icons/duotune/arrows/arr065.svg'
                  className='svg-icon-5 ms-n1 svg-icon-danger'
                />
              )
              }
              {Math.abs(profitLossPercentage) || 0}%
            </span>
          </div>
          <span className="text-gray-500 pt-1 fw-semibold fs-6">Total Invested Amount</span>
        </div>
      </div>
    </div>
  )
}

export { InvestedAmountCard }
