const AUMCard = ({ AUM }: any) => {
  return (
    <div className={`card card-flush h-md-50 mb-5 mb-xl-10`} >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column w-100'>
          <div className='d-flex align-items-center flex-wrap gap-6 w-100'>
            <div className='d-flex align-items-center w-100'>
              <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>₹</span>

              <span className='fs-3hx fw-bold text-dark me-8 lh-1 ls-n2 w-100'>{AUM}</span>
            </div>
          </div>
          <span className="text-gray-500 pt-3 fw-semibold fs-6">Total AUM</span>
        </div>
      </div>
    </div>
  )
}

export { AUMCard }
