import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import * as Yup from 'yup'
// import { getClient } from '../../single-client/components/profile/core/_requests';
import {UpcomingPaymentsListLoading} from '../components/loading/ListLoading'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {UpcomingPayments} from '../core/_models'
import {addUpcomingPayment, updateUpcomingPayment} from '../core/_requests'

type Props = {
  isClientLoading: boolean
  client: UpcomingPayments
}

const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.@$!%*#?&><)(^-_]).{8,100}$/
const passwordError = () => {
  return (
    <>
      Your password must:
      <ul>
        <li>Include an UPPER and lowercase letter</li>
        <li>Include a number</li>
        <li>Include one or more of these special characters: .@$!%*#?&amp;&gt;&lt;)(^-_</li>
        <li>Be between 8 and 100 characters</li>
      </ul>
    </>
  )
}
const editClientSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  last_name: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
  phone: Yup.number()
    .typeError('Please enter a valid phone number')
    .positive('Please enter a valid phone number')
    .integer('Please enter a valid phone number')
    .min(1000000000, 'Please enter a 10 digit phone number')
    .required('Phone is required'),
  commission_percentage: Yup.number()
    .typeError('Please enter a valid commission percentage between 0 and 100')
    .positive('Please enter a valid commission percentage between 0 and 100')
    .integer('Please enter a valid commission percentage between 0 and 100')
    .min(0, 'Please enter a valid commission percentage between 0 and 100')
    .max(100, 'Please enter a valid commission percentage between 0 and 100'),
  password: Yup.string()
    .matches(passwordRules, {message: passwordError})
    .required('Password is required'),
})

const ClientEditModalForm: FC<Props> = ({client, isClientLoading}) => {
  const {setItemIdForUpdate} = useListView()
  // const { refetch } = useQueryResponse();

  const [clientForEdit, setClientForEdit] = useState<any>({...client}) // Initialize state for client data

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (client.id) {
  //       try {
  //         const fetchedClient = await getClient(client.id); // Fetch client data from API
  //         if (Array.isArray(fetchedClient)) {
  //           getClient(fetchedClient[0]);
  //           setClientForEdit(fetchedClient[0]);
  //         } else {
  //           console.error('Error fetching client data: Response is not an array');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching client data:', error);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [client.id]); // Fetch data when client ID changes

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      // refetch();
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {
      first_name: clientForEdit.first_name || '', // Autofill form fields with client data
      last_name: clientForEdit.last_name || '',
      phone: clientForEdit.phone || '',
      email: clientForEdit.email || '',
      whatsapp: clientForEdit.whatsapp || '',
      date_of_joining: clientForEdit.date_of_joining || '',
      commission_percentage: clientForEdit.commission_percentage || '',
      password: clientForEdit.password || '',
    },
    enableReinitialize: true,
    validationSchema: editClientSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        // Validate the presence of ID and call the appropriate API function
        const rawDate = new Date(values.date_of_joining)
        const year = rawDate.getFullYear()
        const month = String(rawDate.getMonth() + 1).padStart(2, '0')
        const day = String(rawDate.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`
        if (client.id) {
          // Update existing client
          await updateUpcomingPayment({
            id: client.id,
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            email: values.email,
            whatsapp: values.whatsapp,
            date_of_joining: formattedDate,
            password: values.password,
            status: true,
          })
        } else {
          // Add new client
          await addUpcomingPayment({
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            email: values.email,
            whatsapp: values.whatsapp,
            date_of_joining: formattedDate,
            password: values.password,
            status: true,
          })
        }
        cancel(true)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_client_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_client_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_client_header'
          data-kt-scroll-wrappers='#kt_modal_add_client_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>First Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='First name'
              {...formik.getFieldProps('first_name')}
              type='text'
              name='first_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.first_name && formik.errors.first_name},
                {
                  'is-valid': formik.touched.first_name && !formik.errors.first_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.first_name as string}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Last Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Last name'
              {...formik.getFieldProps('last_name')}
              type='text'
              name='last_name'
              className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.last_name as string}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Phone</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Phone'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
              type='tel'
              pattern='[0-9]{10}'
              name='phone'
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {/* end::Input */}
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone as string}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            <label className='fw-bold fs-6 mb-2'>Date of joining</label>
            <Flatpickr
              value={formik.values.date_of_joining}
              onChange={(date) => formik.setFieldValue('date_of_joining', date[0])}
              options={{
                dateFormat: 'Y-m-d',
              }}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.date_of_joining && formik.errors.date_of_joining},
                {
                  'is-valid': formik.touched.date_of_joining && !formik.errors.date_of_joining,
                }
              )}
              placeholder='Pick date'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {formik.touched.date_of_joining && formik.errors.date_of_joining && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.date_of_joining as string}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-2'>Commission Percentage</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Commission Percentage'
              {...formik.getFieldProps('commission_percentage')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {
                  'is-invalid':
                    formik.touched.commission_percentage && formik.errors.commission_percentage,
                },
                {
                  'is-valid':
                    formik.touched.commission_percentage && !formik.errors.commission_percentage,
                }
              )}
              type='number'
              pattern='[0-9]{10}'
              name='commission_percentage'
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {/* end::Input */}
            {formik.touched.commission_percentage && formik.errors.commission_percentage && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.commission_percentage as string}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Password</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              type='password'
              name='password'
              autoComplete='off'
              disabled={formik.isSubmitting || isClientLoading}
            />
            {/* end::Input */}
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password as string}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-clients-modal-action='cancel'
            disabled={formik.isSubmitting || isClientLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-clients-modal-action='submit'
            disabled={isClientLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isClientLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isClientLoading) && <UpcomingPaymentsListLoading />}
    </>
  )
}

export {ClientEditModalForm}
