import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {getUpcomingPaymentById} from '../core/_requests'
import {useListView} from '../core/ListViewProvider'
import {ClientEditModalFormWrapper} from './ClientEditModalFormWrapper'
import {ClientEditModalHeader} from './ClientEditModalHeader'

const ClientEditModal = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const transaction = {
    id: itemIdForUpdate,
  }
  const {isLoading, data, error} = useQuery(
    `${QUERIES.UPCOMING_PAYMENTS_LIST}-payment-${itemIdForUpdate}`,
    () => {
      return getUpcomingPaymentById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: false,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <ClientEditModalHeader UpcomingPayments={transaction} />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <ClientEditModalFormWrapper
                isClientLoading={isLoading}
                client={transaction}
                isLoading={isLoading}
                itemIdForUpdate={itemIdForUpdate}
                error={error}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {ClientEditModal}
