import { useParams } from 'react-router-dom'
import { StocksListWrapper } from './stocks-list/StockList'
import { ListViewProvider } from './stocks-list/core/ListViewProvider'
import { QueryRequestProvider } from './stocks-list/core/QueryRequestProvider'
import { StockQueryResponseProvider } from './stocks-list/core/StockQueryResponseProvider'

const StocksPage = () => {
  return (
    <>
      <QueryRequestProvider>
          <StockQueryResponseProvider>
            <ListViewProvider>
              <StocksListWrapper />
            </ListViewProvider>
          </StockQueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export default StocksPage
