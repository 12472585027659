import axios, { AxiosResponse } from "axios"

const API_URL = process.env.REACT_APP_API_URL
const GET_TOTAL_CLIENTS = `${API_URL}/gettotalclients`
const GET_INVESTED_AMOUNT = `${API_URL}/getinvestedamount`
const GET_AUM = `${API_URL}/getaum`

const getTotalClients = (): Promise<any> => {
  return axios.get(`${GET_TOTAL_CLIENTS}`).then((response: AxiosResponse) => {
    const responseData = response.data

    if (responseData.success) {
      return responseData
    } else {
      throw new Error(responseData.message)
    }
  })
}

const getInvestedAmount = (): Promise<any> => {
  return axios.get(`${GET_INVESTED_AMOUNT}`).then((response: AxiosResponse) => {
    const responseData = response.data

    if (responseData.success) {
      return responseData
    } else {
      throw new Error(responseData.message)
    }
  })
}

const getAUM = (): Promise<any> => {
  return axios.get(`${GET_AUM}`).then((response: AxiosResponse) => {
    const responseData = response.data
    if (responseData.success) {
      return responseData
    } else {
      throw new Error(responseData.message)
    }
  })
}

// const disableClient = (clientId: ID): Promise<void> => {
//   const requestData = {
//     id: clientId,
//   }

//   return axios
//     .post(MAKE_INACTIVE_URL, requestData)
//     .then((response: AxiosResponse<void>) => response.data)
// }

export {
  getAUM, getInvestedAmount, getTotalClients
}
