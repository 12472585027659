import {ReactNode, useMemo} from 'react'
import {Column, ColumnInstance, useSortBy, useTable} from 'react-table'
import {KTCardBody} from '../../../_metronic/helpers'
import {CustomHeaderColumn} from './CustomHeaderColumn'
import CustomRow from './CustomRow'

interface TableProps<TableDataType extends object> {
  tableData: TableDataType[]
  columns: readonly Column<TableDataType>[]
  children?: ReactNode
}

const AppTable = <TableDataType extends object>({
  tableData,
  columns,
  children = null,
}: TableProps<TableDataType>) => {
  const data = useMemo(() => tableData, [tableData])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow, headerGroups} = useTable(
    {
      columns: columns as readonly Column<TableDataType>[],
      data,
    },
    useSortBy
  )

  return (
    <KTCardBody className='py-4 d-flex flex-column'>
      <div className='table-responsive'>
        <table
          id='kt_table_client-transactions'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            {/* <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr> */}
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  //@ts-ignore
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {children}
    </KTCardBody>
  )
}

export {AppTable}
