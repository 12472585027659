/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {UpcomingPayments} from '../../core/_models'

type Props = {
  UpcomingPayments: UpcomingPayments
}

const InfoCell: FC<Props> = ({UpcomingPayments}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {`${UpcomingPayments.first_name} ${UpcomingPayments.last_name}`}
        </a>
        <span>{UpcomingPayments.email}</span>
      </div>
    </div>
  )
}

export default InfoCell
