// @ts-nocheck
import { Column } from 'react-table'
import { StockCustomHeader } from './StockCustomHeader'
import { StockActionsCell } from './StockActionsCell'

const clientsColumns: ReadonlyArray<Column<Stock>> = [
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Stock Name' className='min-w-125px' />
    ),
    id: 'symbol',
    accessor: 'stock_name',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Stock Code' className='min-w-125px' />
    ),
    id: 'stock_code',
    accessor: 'stock_code',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Current Price' className='min-w-125px' />
    ),
    id: 'current_price',
    accessor: 'current_price',
  },
  {
    Header: (props) => (
      <StockCustomHeader tableProps={props} title='Actions' className='min-w-125px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => (
        <StockActionsCell id={props.data[props.row.index]?.id} />
    ),
  }
];

export { clientsColumns }
