import {FC} from 'react'

type Props = {
  wallet_balance?: string
}

const WalletCell: FC<Props> = ({wallet_balance}) => (
  <div className='badge badge-light fw-bolder'>{wallet_balance || 'Null'}</div>
  // <div className='badge badge-light fw-bolder'>{last_login}</div>
)

export default WalletCell
