import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { AUMCard } from './components/AUMCard'
import { InvestedAmountCard } from './components/InvestedAmountCard'
import { PerformanceCard } from './components/PerformanceCard'
import { TotalClientsCard } from './components/TotalClientsCard'
import { getAUM, getInvestedAmount, getTotalClients } from './core/_requests'
import { useAuth } from '../../modules/auth'
import { useNavigate } from 'react-router-dom'
import { routeMap } from '../../routing/routes'

const DashboardPage: FC = () => {
  const [totalClients, setTotalClients] = useState(0)
  const [investedAmount, setInvestedAmount] = useState(0)
  const [AUM, setAUM] = useState(0)
  const [profitLossPercentage, setProfitLossPercentage] = useState(0)
  const { isAdminRole } = useAuth()
  const navigate = useNavigate()

  const fetchTotalClients = async () => {
    try {
      const response = await getTotalClients()
      setTotalClients(response.data)
    } catch (error) {
      console.error('fetchTotalClients -> error', error)
    }
  }

  const fetchInvestedAmount = async () => {
    try {
      const response = await getInvestedAmount()
      setInvestedAmount(response.data)
    } catch (error) {
      console.error('fetchInvestedAmount -> error', error)
    }
  }

  const fetchAUM = async () => {
    try {
      const response = await getAUM()
      setAUM(response.data)
    } catch (error) {
      console.error('fetchAUM -> error', error)
    }
  }

  useEffect(() => {
    if (!isAdminRole) {
      navigate(routeMap.error)
      return
    }
    fetchTotalClients()
    fetchInvestedAmount()
    fetchAUM()
  }, [])

  useEffect(() => {
    const parseNumber = (value: string) => parseFloat(value.replace(/,/g, ''));

    const parsedAUM = parseNumber(AUM?.toString());
    const parsedInvestedAmount = parseNumber(investedAmount?.toString());

    if (parsedInvestedAmount !== 0) {
      const profitLossPercentage = ((parsedAUM - parsedInvestedAmount) / parsedInvestedAmount) * 100;
      setProfitLossPercentage(parseFloat(profitLossPercentage.toFixed(2)));
      console.log(parsedAUM, parsedInvestedAmount, profitLossPercentage);
    } else {
      setProfitLossPercentage(0);
    }
  }, [AUM, investedAmount]);

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <TotalClientsCard
            totalClients={totalClients}
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Clients'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <div className='h-md-50 mb-5 mb-xl-10' />
        </div>

        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <InvestedAmountCard
            investedAmount={investedAmount}
            profitLossPercentage={profitLossPercentage}
          />
          <AUMCard AUM={AUM} />
        </div>

        <div className='col-xxl-6 d-flex flex-column justify-content-between gap-8'>
          {/* <EngageWidget10 className='h-md-100' /> */}
          {/* <PerformanceCard /> */}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
