import {useEffect, useState} from 'react'
import {KTCard, Response} from '../../../../../_metronic/helpers'
import ListHeader from './components/header/ListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponseData,
  useQueryResponseLoading,
} from './core/QueryResponseProvider'

import {UnpaidTransaction} from './core/_models'
import UnpaidTransactionTable from './table/UnPaidTransactionTable'
import {useClientIdContext} from './core/ClientIdContext'
import {useParams} from 'react-router-dom'
import {useUserRoleNavigation} from '../../../../hooks/userRoleBasedNavigation'
import {useAuth} from '../../../../modules/auth'
import {AppTable} from '../../../../components/table/CustomTable'
import UnPaidTransactionColumns from './table/columns/_columns'
import CustomLoader from '../../../../components/CustomLoader'

const UnpaidTransactionList: React.FC = () => {
  useUserRoleNavigation()
  const isLoading = useQueryResponseLoading()
  const {itemIdForUpdate} = useListView()
  const initialTransactions: Response<UnpaidTransaction[]> | never[] = useQueryResponseData()
  const [transactions, setTransactions] = useState<UnpaidTransaction[]>([])
  const [filteredTransactions, setFilteredTransactions] = useState<UnpaidTransaction[]>([])
  const {isAdminRole} = useAuth()

  useEffect(() => {
    if (Array.isArray(initialTransactions)) {
      setTransactions(initialTransactions)
      setFilteredTransactions(initialTransactions)
    }
  }, [initialTransactions])
  //TODO: Unpaid Transactions - need to change the handle search function
  const handleSearch = (searchTerm: string): void => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    const filtered = transactions.filter((transaction: UnpaidTransaction) => {
      return `${transaction?.first_name} ${transaction?.last_name}`
        .toLowerCase()
        .includes(lowerCaseSearchTerm)
    })
    setFilteredTransactions(filtered)
  }

  return (
    <>
      <KTCard>
        {isAdminRole && <ListHeader onSearch={handleSearch} />}
        <AppTable columns={UnPaidTransactionColumns} tableData={filteredTransactions}>
          {isLoading && <CustomLoader />}
        </AppTable>
      </KTCard>
    </>
  )
}

const UnpaidTransactionWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UnpaidTransactionList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export default UnpaidTransactionWrapper
