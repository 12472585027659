import clsx from 'clsx';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';
import { StocksListLoading } from '../components/loading/StocksListLoading';
import { useListView } from '../core/ListViewProvider';
import { useQueryResponse } from '../core/StockQueryResponseProvider';
import { Stock } from '../core/_models';
import { addStock, editStock, getStocks } from '../core/_requests';
import { toast } from 'react-toastify';

type Props = {
  isStockLoading: boolean;
  stock: Stock;
};

const editStockSchema = Yup.object().shape({
  stock_code: Yup.string().required('Stock code is required'),
  stock_name: Yup.string().required('Stock name is required'),
  current_price: Yup.number().required('Price is required').positive('Price must be a positive number'),
});

const StockEditModalForm: FC<Props> = ({ stock, isStockLoading }) => {
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [stockForEdit, setStockForEdit] = useState<Stock | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (stock.id) {
        setIsEdit(true);
        try {
          const fetchedStock = await getStocks(); // Fetch client data from API
          if (Array.isArray(fetchedStock)) {
            let currentStock = fetchedStock.filter(currentStock => currentStock.id === stock.id)[0];
            setStockForEdit(currentStock);
            console.log(currentStock);
          } else {
            toast.error('Error fetching stock data: Response is not an array');
          }
        } catch (error: any) {
          toast.error('Error fetching stock data:', error);
        }
      }
    };

    fetchData();
  }, [stock.id]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: {
      id: stockForEdit?.id || '',
      stock_name: stockForEdit?.stock_name || '',
      stock_code: stockForEdit?.stock_code || '',
      current_price: stockForEdit?.current_price || 0,
    },
    enableReinitialize: true,
    validationSchema: editStockSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (isEdit) {
          // Update existing stock
          await editStock({
            id: stock.id,
            stock_name: values.stock_name,
            stock_code: values.stock_code.toUpperCase(),
            current_price: values.current_price,
          });
          toast.success('Stock updated successfully')
        } else {
          // Add new stock
          await addStock({
            stock_name: values.stock_name,
            stock_code: values.stock_code.toUpperCase(),
            current_price: values.current_price,
          });
          toast.success('Stock added successfully')
        }
        cancel(true);
      } catch (ex: any) {
        console.error(ex);
        toast.error(ex.message)
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form id='kt_modal_add_client_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_client_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_client_header'
          data-kt-scroll-wrappers='#kt_modal_add_client_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Stock Code</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Stock Code'
              {...formik.getFieldProps('stock_code')}
              type='text'
              name='stock_code'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.stock_code && formik.errors.stock_code },
                {
                  'is-valid': formik.touched.stock_code && !formik.errors.stock_code,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isStockLoading}
            />
            {formik.touched.stock_code && formik.errors.stock_code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.stock_code}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Stock Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Stock Name'
              {...formik.getFieldProps('stock_name')}
              type='text'
              name='stock_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.stock_name && formik.errors.stock_name },
                {
                  'is-valid': formik.touched.stock_name && !formik.errors.stock_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isStockLoading}
            />
            {formik.touched.stock_name && formik.errors.stock_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.stock_name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Current Price</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Current Price'
              {...formik.getFieldProps('current_price')}
              type='number'
              name='current_price'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.current_price && formik.errors.current_price },
                {
                  'is-valid': formik.touched.current_price && !formik.errors.current_price,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isStockLoading}
            />
            {formik.touched.current_price && formik.errors.current_price && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.current_price}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-clients-modal-action='cancel'
            disabled={formik.isSubmitting || isStockLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-clients-modal-action='submit'
            disabled={isStockLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isStockLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isStockLoading) && <StocksListLoading />}
    </>
  );
};

export { StockEditModalForm };
