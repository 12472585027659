import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UpcomingPayments, UpcomingPaymentsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
//TODO: change the urlS here
const UPCOMING_PAYMENTS_URL = `${API_URL}/client`
const GET_UPCOMING_PAYMENTS_URL = `${API_URL}/getallclient`
const MAKE_INACTIVE_URL = `${API_URL}/makeinactive`
const ADD_UPCOMING_PAYMENTS_URL = `${API_URL}/addclient`

const getUpcomingPayments = (
  query: string,
  clientId: number | string = 28
): Promise<UpcomingPaymentsQueryResponse> => {
  const url = clientId ? `${GET_UPCOMING_PAYMENTS_URL}/${clientId}` : GET_UPCOMING_PAYMENTS_URL
  console.log('UPCOMING PAYMENTS url', url)
  return axios.get(`${url}?${query}`).then((response: AxiosResponse) => {
    const responseData = response.data

    if (responseData.success) {
      return responseData.client_data
    } else {
      // Handle the case where the API request was successful, but 'success' is false
      throw new Error(responseData.message)
    }
  })
}

const disableUpcomingPayments = (clientId: ID): Promise<void> => {
  const requestData = {
    id: clientId,
  }

  return axios
    .post(MAKE_INACTIVE_URL, requestData)
    .then((response: AxiosResponse<void>) => response.data)
}

const disableSelected_UPCOMING_PAYMENTS = (clientIds: Array<ID>): Promise<void[]> => {
  const disablePromises: Promise<void>[] = clientIds.map((id) => disableUpcomingPayments(id))

  return Promise.all(disablePromises)
}

const addUpcomingPayment = (client: UpcomingPayments): Promise<UpcomingPayments | undefined> => {
  return axios
    .post(ADD_UPCOMING_PAYMENTS_URL, client)
    .then((response: AxiosResponse<Response<UpcomingPayments>>) => response.data)
    .then((response: Response<UpcomingPayments>) => response.data)
}

const getUpcomingPaymentById = (id: ID): Promise<UpcomingPayments | undefined> => {
  return axios
    .get(`${UPCOMING_PAYMENTS_URL}/${id}`)
    .then((response: AxiosResponse<Response<UpcomingPayments>>) => response.data)
    .then((response: Response<UpcomingPayments>) => response.data)
}

const updateUpcomingPayment = (client: UpcomingPayments): Promise<UpcomingPayments | undefined> => {
  return axios
    .post(`${API_URL}/editclient`, client)
    .then((response: AxiosResponse<Response<UpcomingPayments>>) => response.data)
    .then((response: Response<UpcomingPayments>) => response.data)
}

export {
  addUpcomingPayment,
  disableUpcomingPayments,
  disableSelected_UPCOMING_PAYMENTS,
  getUpcomingPaymentById,
  getUpcomingPayments,
  updateUpcomingPayment,
}
