import {FC} from 'react'
import {ID} from '../../../../../../_metronic/helpers'
import {UpcomingPayments} from '../core/_models'
import {ClientEditModalForm} from './ClientEditModalForm'

type Props = {
  isClientLoading: boolean
  client: UpcomingPayments
  isLoading: boolean
  itemIdForUpdate: ID
  error: unknown
}

const ClientEditModalFormWrapper: FC<Props> = ({
  isClientLoading,
  client,
  isLoading,
  itemIdForUpdate,
  error,
}) => {
  if (!itemIdForUpdate) {
    return <ClientEditModalForm isClientLoading={isClientLoading} client={{id: undefined}} />
  }

  if (!isLoading && !error && client) {
    return <ClientEditModalForm isClientLoading={isClientLoading} client={client} />
  }

  return null
}

export {ClientEditModalFormWrapper}
