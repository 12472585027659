import {Column} from 'react-table'
import CustomHeader from './CustomHeader'
import {UnpaidTransaction} from '../../core/_models'
import {getFormattedDate} from '../../Utils/formatDate'

const UnPaidTransactionColumns: ReadonlyArray<Column<UnpaidTransaction>> = [
  //TODO: change the column names and fields here

  {
    Header: (props) => <CustomHeader tableProps={props} title='Id' className='min-w-125px' />,
    accessor: 'id',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Transaction Id' className='min-w-125px' />
    ),
    accessor: 'transaction_id',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Amount' className='min-w-125px' />,
    accessor: 'amount',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Date of Issue' className='min-w-125px' />
    ),
    accessor: 'created_at',
    Cell: ({...props}) => (
      <span>{getFormattedDate(props.data[props.row.index].created_at as Date)}</span>
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Commission Amount' className='min-w-125px' />
    ),
    accessor: 'commission_amount',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Commission Percentage' className='min-w-125px' />
    ),
    accessor: 'commission_percentage',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Commission Paid Amount' className='min-w-125px' />
    ),
    accessor: 'commission_paid_amount',
  },
]

export default UnPaidTransactionColumns
