import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {UnpaidTransaction, unPaidTransactionQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
//TODO: change the urlS here
const TRANSACTIONS_URL = `${API_URL}/getallunpaidtransactions`
const GET_TRANSACTIONS_URL = `${API_URL}/getallunpaidtransactions`
const MAKE_INACTIVE_URL = `${API_URL}/makeinactive`
const ADD_TRANSACTIONS_URL = `${API_URL}/addclient`

const getUnpaidTransaction = (
  query: string,
  clientId: number | string = 28
): Promise<unPaidTransactionQueryResponse> => {
  const url = clientId ? `${GET_TRANSACTIONS_URL}/${clientId}` : GET_TRANSACTIONS_URL

  return axios.get(`${url}?${query}`).then((response: AxiosResponse) => {
    const responseData = response?.data
    if (responseData.success) {
      return responseData.client_data
    } else {
      // Handle the case where the API request was successful, but 'success' is false
      throw new Error(responseData.message)
    }
  })
}

const disableTransaction = (clientId: ID): Promise<void> => {
  const requestData = {
    id: clientId,
  }

  return axios
    .post(MAKE_INACTIVE_URL, requestData)
    .then((response: AxiosResponse<void>) => response.data)
}

const disableSelectedTransactions = (clientIds: Array<ID>): Promise<void[]> => {
  const disablePromises: Promise<void>[] = clientIds.map((id) => disableTransaction(id))

  return Promise.all(disablePromises)
}

const addTransaction = (client: UnpaidTransaction): Promise<UnpaidTransaction | undefined> => {
  return axios
    .post(ADD_TRANSACTIONS_URL, client)
    .then((response: AxiosResponse<Response<UnpaidTransaction>>) => response.data)
    .then((response: Response<UnpaidTransaction>) => response.data)
}

const getTransactionById = (id: ID): Promise<UnpaidTransaction | undefined> => {
  return axios
    .get(`${TRANSACTIONS_URL}/${id}`)
    .then((response: AxiosResponse<Response<UnpaidTransaction>>) => response.data)
    .then((response: Response<UnpaidTransaction>) => response.data)
}

const updateTransaction = (client: UnpaidTransaction): Promise<UnpaidTransaction | undefined> => {
  return axios
    .post(`${API_URL}/editclient`, client)
    .then((response: AxiosResponse<Response<UnpaidTransaction>>) => response.data)
    .then((response: Response<UnpaidTransaction>) => response.data)
}

export {
  addTransaction,
  disableTransaction,
  disableSelectedTransactions,
  getTransactionById,
  getUnpaidTransaction,
  updateTransaction,
}
