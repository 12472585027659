import UnpaidTransactionWrapper from './transaction-list/UnpaidTransactionList'

const UnPaidTransactions = () => {
  return (
    <>
      <h3>UnPaid Transactions</h3>
      <div>
        <div className={`card`}>
          {/* begin::Body */}
          <div className='card-body py-3'>
            <UnpaidTransactionWrapper />
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  )
}

export default UnPaidTransactions
