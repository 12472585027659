import {FC, ReactNode} from 'react'
import {KTSVG} from '../../../_metronic/helpers'

interface AppEditModalHeaderProps {
  children?: ReactNode
  onDismiss: (id?: string) => void
}

const EditModalHeader: FC<AppEditModalHeaderProps> = ({children = null, onDismiss}) => {
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      {children}
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-clients-modal-action='close'
        onClick={() => onDismiss()}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export default EditModalHeader
