import { KTCard } from '../../../../_metronic/helpers'
import { useQueryResponseData, useQueryResponseLoading } from './core/StockQueryResponseProvider'
import { useEffect, useMemo, useState } from 'react'
import { clientsColumns } from './table/columns/_columns'
import { AppTable } from '../../../components/table/CustomTable'
import { Stock } from './core/_models'
import CustomLoader from '../../../components/CustomLoader'
import { useListView } from './core/ListViewProvider'
import { StockEditModal } from './stock-edit-modal/StockEditModal'
import AppListHeader from '../../../components/HeaderComponents/ListHeader'
import AppSearchComponent from '../../../components/HeaderComponents/SearchComponent'
import AppListGrouping from '../../../components/HeaderComponents/ListGrouping'
import AppListToolbar from '../../../components/HeaderComponents/ListToolbar'
import { CustomPagination } from '../../../components/CustomPagination'

const StocksListWrapper = () => {
  const initialStocks = useQueryResponseData()
  const [stocks, setStocks] = useState<Stock[]>([])
  const [filteredStocks, setFilteredStocks] = useState<Stock[]>([])
  const isLoading = useQueryResponseLoading()
  const columns = useMemo(() => clientsColumns, [])
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
  } = useListView()

  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0,
  })

  useEffect(() => {
    if (Array.isArray(initialStocks)) {
      setStocks(initialStocks)
      setFilteredStocks(initialStocks)
      setPagination((prev) => ({
        ...prev,
        total: initialStocks.length,
        last_page: Math.ceil(initialStocks.length / prev.per_page),
      }))
    }
  }, [initialStocks])

  const handleSearch = (searchTerm: string): void => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    const filtered = stocks.filter((stock: Stock) => {
      return `${stock?.stock_name} ${stock?.stock_code}`
        .toLowerCase()
        .includes(lowerCaseSearchTerm)
    })
    setFilteredStocks(filtered)
    setPagination((prev) => ({
      ...prev,
      current_page: 1,
      total: filtered.length,
      last_page: Math.ceil(filtered.length / prev.per_page),
    }))
  }

  const handlePageChange = (Page: number) => {
    setPagination((prev) => ({
      ...prev,
      current_page: Page,
    }))
  }

  const handlePerPageChange = (perPage: number) => {
    setPagination((prev) => ({
      ...prev,
      per_page: perPage,
      current_page: 1,
      last_page: Math.ceil(prev.total / perPage),
    }))
  }

  const openAddClientModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <>
      <KTCard>
        <AppListHeader>
          <>
            <AppSearchComponent onSearch={handleSearch} searchPlaceholder={"Search Stocks"} />
            <div className='card-toolbar'>
                <AppListToolbar onClick={openAddClientModal} title='Add Stock' />
            </div>
          </>
        </AppListHeader>
        <AppTable columns={columns} tableData={filteredStocks.slice(
          (pagination.current_page - 1) * pagination.per_page,
          pagination.current_page * pagination.per_page
        )}>
          {isLoading && <CustomLoader />}
          <CustomPagination
            pagination={pagination}
            isLoading={isLoading}
            onPageChange={handlePageChange}
            onPerPageChange={handlePerPageChange}
          />
        </AppTable>
      </KTCard>
      {itemIdForUpdate !== undefined && <StockEditModal />}
    </>
  )
}

export { StocksListWrapper }
