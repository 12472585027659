import UpcomingPaymentListWrapper from './upcoming-payment-list/UpcomingPaymentList'

const UnPaidTransactions = () => {
  return (
    <>
      <h3>Upcoming Payments</h3>
      <div>
        <div className={`card`}>
          {/* begin::Body */}
          <div className='card-body py-3'>
            <UpcomingPaymentListWrapper />
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  )
}

export default UnPaidTransactions
