const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const getFormattedDate = (
  date: Date,
  options: Intl.DateTimeFormatOptions = defaultOptions
) => {
  const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(new Date(date))
  return formattedDate
}
