import React from 'react'
import clsx from 'clsx'

interface PaginationProps {
  pagination: {
    current_page: number
    last_page: number
    per_page: number
    total: number
  }
  isLoading: boolean
  onPageChange: (page: number) => void
  onPerPageChange: (perPage: number) => void
}

const CustomPagination: React.FC<PaginationProps> = ({ pagination, isLoading, onPageChange, onPerPageChange }) => {
  const handlePageChange = (page: number) => {
    if (!isLoading && page !== pagination.current_page && page > 0 && page <= pagination.last_page) {
      onPageChange(page)
    }
  }

  const createPaginationItems = () => {
    const items = []
    for (let i = 1; i <= pagination.last_page; i++) {
      items.push(
        <li
          key={i}
          className={clsx('page-item', { active: pagination.current_page === i, disabled: isLoading })}
        >
          <a
            className='page-link'
            onClick={() => handlePageChange(i)}
            style={{ cursor: 'pointer' }}
          >
            {i}
          </a>
        </li>
      )
    }
    return items
  }

  return (
    <div className='row d-flex justify-content-end mt-auto'>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end pt-5 pb-4'>
        <div className='d-flex align-items-center gap-8 pe-4'>
          <ul className='pagination'>
            <li className={clsx('page-item', { disabled: isLoading || pagination.current_page === 1 })}>
              <a
                className='page-link'
                onClick={() => handlePageChange(pagination.current_page - 1)}
                style={{ cursor: 'pointer' }}
              >
                Previous
              </a>
            </li>
            {createPaginationItems()}
            <li className={clsx('page-item', { disabled: isLoading || pagination.current_page === pagination.last_page })}>
              <a
                className='page-link'
                onClick={() => handlePageChange(pagination.current_page + 1)}
                style={{ cursor: 'pointer' }}
              >
                Next
              </a>
            </li>
          </ul>
          <div className='form-group'>
            <select
              className='form-control'
              value={pagination.per_page}
              onChange={(e) => onPerPageChange(Number(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CustomPagination }
