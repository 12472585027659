import {FC, PropsWithChildren, ReactNode, createContext, useContext, useState} from 'react'
interface providerProps {
  children: ReactNode
}
type ClientIdContextType = {
  id: string
  changeId: (id: string) => void
}
const clientIdContextInitialState = {
  id: '',
  changeId: (id: string) => {},
}

const clientIdContext = createContext<ClientIdContextType>(clientIdContextInitialState)

export const useClientIdContext = () => useContext(clientIdContext)

const ClientContextProvider: FC<providerProps> = ({children}) => {
  const [id, setId] = useState('')
  const changeId = (id: string) => setId(id)
  return <clientIdContext.Provider value={{id, changeId}}>{children}</clientIdContext.Provider>
}

export default ClientContextProvider
